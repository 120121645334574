<template>
  <page-layout>
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        class="elevation-1 mt-3"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Silmek İstediğinizden Emin Misiniz ?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="closeDelete"
                    >İptal Et</v-btn
                  >
                  <v-btn color="red darken-1" text @click="deleteItemConfirm"
                    >Evet</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item._id`]="{ item }">
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </page-layout>
</template>

<script>
import axios from '@axios';
import PageLayout from '@/components/PageLayout';

export default {
  name: 'Candidate',
  components: {
    PageLayout
  },
  data: () => ({
    isLoading: true,
    dialogDelete: false,
    selectedDeleteItemId: '',
    items: [],
    headers: [
      {
        text: 'T.C.',
        value: 'idNumber',
        sortable: false
      },
      {
        text: 'İsim',
        value: 'name',
        sortable: false
      },
      {
        text: 'Soyisim',
        value: 'lastname',
        sortable: false
      },
      {
        text: 'Telefon',
        value: 'phone',
        sortable: false
      },
      {
        width: '100px',
        text: 'İşlemler',
        value: '_id',
        sortable: false
      }
    ]
  }),
  mounted() {
    this.list();
  },
  methods: {
    deleteItem(item) {
      this.dialogDelete = true;
      this.selectedDeleteItemId = item._id;
    },
    deleteItemConfirm() {
      this.closeDelete();

      axios
        .get(`/delete-candidate/${this.selectedDeleteItemId}`)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.warning('Aday Silindi.');
            this.list();
          } else {
            this.$toast.error('Unexpected error.');
          }
        })
        .catch(() => {
          this.$toast.error('API Error.');
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    list() {
      axios
        .get('/list-candidate')
        .then((response) => {
          if (response?.data?.result) {
            this.items = response.data.result;
            this.isLoading = false;
          }
        })
        .catch(() => {
          this.$toast.error('Error while getting test list');
        });
    }
  }
};
</script>
